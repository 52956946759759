.footer {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  padding: 1rem 0;
  gap: 10rem;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px; /* Establece un ancho máximo para centrar el contenido en pantallas más grandes */
  padding: 0 20px; /* Añade un relleno horizontal para pantallas más pequeñas */
}
.footer-content-img{
  padding: 10px;
}
.footer-content-img img {
  max-width: 80%; /* Asegura que la imagen no desborde el contenedor */
}

.newsletter {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center; /* Centrar el formulario en pantallas grandes */
}

input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  flex: 1; /* Asegura que los inputs se expandan según el espacio disponible */
}

.btn-send-footer {
  background-color: #3786E9;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 20px; /* Bordes redondeados para un look moderno */
  border: none; 
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  margin-left: auto; /* Alinea el botón al final del contenedor */
}

.btn-send-footer:hover {
  background-color:  #DCE05C;
  transition: background-color 0.3s;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 20px; /* Bordes redondeados para un look moderno */
  border: none; 
  cursor: pointer;
  font-size: 1rem;
  margin-left: auto; /* Alinea el botón al final del contenedor */
}
/* Asegura que el footer-bottom esté siempre al final */
.footer-bottom {
  text-align: center;
  margin: 0; 
  font-size: 18px;
  color: rgba(182, 181, 178, 0.775);
  padding: 20px;/* Espaciado entre el contenido y el texto de derechos */
}

/* Media Queries para dispositivos móviles */
@media (max-width: 768px) {
  .footer{
    flex-direction: column;
    gap: 0.5rem;
  }
  .footer-content {
    align-items: center; /* Centrar el contenido en dispositivos más chicos celulares */
  }

  .newsletter {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .form-container {
    flex-direction: column;
    width: 100%;
  }

  input, button {
    width: 100%; /* Hacer que los inputs y el botón ocupen todo el ancho disponible */
    margin-right: 0; /* Eliminar margen derecho */
  }

  .btn-send-footer {
    margin-top: 10px; /* Agregar margen superior para separar el botón del input */
  }
  .footer-bottom{
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 15px;
  }
  .footer-content-img  {
   width: 100%;
   display: flex;
   justify-content: center;
  }
  .newsletter p {
    font-size: 14px;
  }

  input, button {
    padding: 8px;
    font-size: 14px;
  }

  button {
    padding: 8px 16px;
  }
}




