/* .file-upload {
    display: flex;
   
  }
   */
  .file-upload input[type="file"] {
    padding: 0.8rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
  }
  
  .file-upload .form-label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #555;
  }
  
  .file-upload p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #333;
    text-align: center;
  }
  
  .file-upload p.error {
    color: red;
  }
  
  .file-upload a {
    background-color: #8492a1;
    color: white;
    border: none;
    padding: 0.8rem ;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 4px;
  }
  
  .file-upload a:hover {
    background-color: #616a75;
  }