.expand-container {
  visibility: hidden; /* Oculto por defecto */
  position: absolute;
  top: 0;
  right: 0; /* Fuera de la pantalla por completo */
  width: 300px;
  background: rgb(255, 255, 255);
  z-index: 1800;
  overflow: hidden;
  border-radius: 5px;
  opacity: 0; /* Invisible al principio */
  transform: translateX(0);
  transition: right 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s; /* Transición para `right` y `opacity` */
  height: 100%;
  padding-top: 50px;
}

.expand-container.open {
  right: 0; /* Desplaza el contenedor a la vista */
  opacity: 1; /* Hacerlo visible */
  visibility: visible; /* Mostrarlo */
  transition: right 0.3s ease, opacity 0.3s ease, visibility 0s; /* Animar su aparición */
}

/* Botón de cierre */
.close-button {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  align-self: flex-end; 
  margin: 1rem;
}

/* Links del contenido */
.content-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.content-link a, .content-link  {
  margin: 10px;
  text-decoration: none;
  color: #000;
}

.content-link a:hover, .content-link  {
  color: #007bff;
}

.signup-button-two {
  background-color: #007bff;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 20px; /* Bordes redondeados para un look moderno */
  border: none; 
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  margin-left: auto; /* Alinea el botón al final del contenedor */
}

.signup-button-two:hover {
  background-color: #58a1f0;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 20px; /* Bordes redondeados para un look moderno */
  border: none; 
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  margin-left: auto; /* Alinea el botón al final del contenedor */
}




/* Estilos específicos para móviles */
@media (max-width: 768px) {
  /* body, html {
    overflow-x: hidden; /* Evita el desplazamiento horizontal en móviles */
  /* } */

  .expand-container {
    right: 0; /* Fuera de la pantalla en móviles */
  }

  .expand-container.open {
    display: block;
    right: 0; /* Mostrar el contenedor en móviles */
    opacity: 1;
    visibility: visible;
    transition: right 0.3s ease, opacity 0.3s ease;
  }
}
