.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
}

.modal-content {
    gap: 10px;
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 400px;
    width: 100%;
    z-index: 1001; /* Asegura que el contenido del modal esté en el frente */
}

.content-buton-close {
    width: 100%;
    display: flex;
    justify-content: end;
}
.modal-close {
    background-color: rgba(227, 224, 224, 0.453);
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.modal-close:hover {

    background-color: rgba(165, 164, 164, 0.575);
    border: none;
    font-size: 20px;
    cursor: pointer;
    
}

.modal-content h2 {
    margin-bottom: 10px;
    font-size: 24px;
    color:#3786E9
}

.modal-content p {
    margin-bottom: 20px;
    font-size: 16px;
}



.modal-content label {
    margin-left: 8px;
    font-size: 14px;
}

.modal-content input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

.modal-content .button-send {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modal-content .button-send:hover {
    background-color: #45a049;
}


@media(max-width:800px){
    .modal-overlay {
       padding: 15px;
    }
    .modal-content{
        gap: 10px;
    }
    
    .modal-close{
        width: 50px;
    }

    .modal-content h2 {
        margin: 5px;
        font-size: 24px;
        color:#3786E9
    }
  
}