/* --------------------- Premios.css ------------------------ */

.premios-section h4{
  color: #3786E9;
  font-size: 20px;
}
.stong-blue{
  color: #3786E9;
}
.stong-black{
  color: #000000;
}
.premios-section p{
  text-align: start; /* Justifica el texto */
  word-wrap: break-word;
  font-size: 18px;
  

}
.premios-section ul li{ 
  text-align: start; /* Justifica el texto */
  word-wrap: break-word;
  font-size: 18px;
}

  @media ( max-width:1024px){
    .premios-section{
      padding-left: 5rem ;
      padding-right: 5rem;
      text-align:justify; /* Justifica el texto */
      word-wrap: break-word;
    }
  }
  @media ( max-width:800px){
    .premios-section{
      padding-left: 2rem ;
      padding-right: 2rem;
      text-align:justify; /* Justifica el texto */
      word-wrap: break-word;
    }

    .premios-section div h4{
      font-size: 18px;
    }  
    .premios-section p{
      text-align: start; /* Justifica el texto */
      word-wrap: break-word;
      font-size: 16px;
      
    
    }
    .premios-section ul li{ 
      text-align: start; /* Justifica el texto */
      word-wrap: break-word;
      font-size: 16px;
    }
}