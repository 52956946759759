.subscription-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto;
  margin-bottom: 6rem;
}

.subscription-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
}

.subscription-form {
  width: 100%;
}

.form-type-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

 .buttonadd {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.buttonadd:hover {
  background-color: #0056b3;
}

.form-individual,
.form-grupal,
.participant-form  {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: wrap;
}

.form-group.full-width {
  grid-column: span 2;
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
  
}

.selector {
  display: flex;
  width: 300px;
  height: 40px;
  background-color: #ddd;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.selector-button {
  flex: 1;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s;
}

.selector-button.active {
  color: white;
}

.selector-indicator {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #007bff;
  border-radius: 20px;
  top: 0;
  left: 0;
  transition: left 0.3s;
}

.selector-indicator.grupal {
  left: 50%;
}

input[type="text"],
input[type="email"],
input[type="date"],
select {
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
 
}

.participant-form {
  align-items: center;
  gap: 10px;
  padding: 1.5rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-bottom: 1rem;
  grid-column: span 2;
}

.buttoneliminar{
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 15px 5px;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.buttoneliminar:hover {
  background-color: #ff1a1a;
}
.spanparticip{
 font-size: 15px;
 margin: 15px;
 color: #218838;
 border-radius: 20px;
}

.buttonSend {
  border-radius: 20px;
  background-color: #3786E9;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  margin-top: 1.5rem;
  width: 100%;
}

.buttonSend:hover {
  background-color: #DCE05C;
}

.buttonadd {
  background-color: #17a2b8;
  margin-top: 0;
}

.buttonadd:hover {
  background-color: #138496;
}

@media (max-width: 800px) {
  .form-individual,
  .form-grupal,
  .participant-form {
    grid-template-columns: 1fr; /* Cambiamos a una columna */
  }

  .form-group.full-width {
    grid-column: span 1; /* Aseguramos que los elementos ocupen todo el ancho en una columna */
  }

  .participant-form {
    grid-column: span 1; /* Aseguramos que los elementos ocupen todo el ancho en una columna */
  }

  .buttonSend {
    width: 100%; /* Aseguramos que el botón de envío ocupe todo el ancho en móvil */
  }
}

