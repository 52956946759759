/* CarrouselSlide.css */

.carrousel {
    position: relative;
    width: 100%;
    max-width: 100%; /* Ajusta el tamaño máximo del carrusel */
    margin: 0 auto;
}

.slide-image {
    width: 100%;
    height: auto; /* Mantiene la relación de aspecto de la imagen */
}

.slick-slide img {
    border-radius: 8px; /* Agrega bordes redondeados a las imágenes */
}

.slick-dots li button:before {
    color: #007bff; /* Cambia el color de los puntos de navegación */
}

.slick-dots li.slick-active button:before {
    color: #0056b3; /* Cambia el color del punto activo */
}

.slick-prev, .slick-next {
    color: #007bff; /* Cambia el color de las flechas de navegación */
}

/* Estilos personalizados para los botones */
.carrousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 10;
}

.carrousel-btn-prev {
    left: 10px; /* Ajusta la posición del botón izquierdo */
}

.carrousel-btn-next {
    right: 10px; /* Ajusta la posición del botón derecho */
}

/* Estilos responsivos */

/* Para pantallas pequeñas (móviles) */
@media (max-width: 600px) {
    .carrousel{
         display: none; 
    }
    .carrousel-mobile {
        display: block; /* Muestra el carrusel móvil en móviles */
    }

    .carrousel-btn {
        font-size: 1.25rem; /* Tamaño de fuente más pequeño para botones */
        padding: 0.3rem; /* Menos padding para botones */
        display: none;
    }

    .slick-dots li button:before {
        font-size: 10px; /* Tamaño más pequeño para los puntos de navegación */
    }
}

/* Para pantallas medianas (tabletas) */
@media (min-width: 601px) and (max-width: 1024px) {
    .carrousel-mobile {
        display: none; /* Muestra el carrusel móvil en móviles */
    }

    .carrousel-btn {
        font-size: 1.5rem; /* Tamaño de fuente estándar para botones */
        padding: 0.5rem; /* Padding estándar para botones */
    }

    .slick-dots li button:before {
        font-size: 12px; /* Tamaño moderado para los puntos de navegación */
    }
}

/* Para pantallas grandes (escritorios) */
@media (min-width: 1025px) {
    .carrousel-btn {
        font-size: 1.75rem; /* Tamaño de fuente más grande para botones */
        padding: 0.75rem; /* Más padding para botones */
    }

    .slick-dots li button:before {
        font-size: 14px; /* Tamaño más grande para los puntos de navegación */
    }
}

@media (min-width: 600px) {
    .carrousel-mobile {
        display: none; /* Muestra el carrusel móvil en móviles */
    }
}
