.section-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  margin-top: 3.5rem;
}


.container-subtitulo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; /* Alineación centrada */
  margin-bottom:5rem ;
}

.container-subtitulo h2 {
  font-size: 1.5rem; 
  font-weight: 400;
  margin-bottom: 4rem;
  width: 70%;
  max-width: 100%; /* Limita el ancho para que el texto quede en 3 renglones */
  text-align: justify; /* Justifica el texto */
  word-wrap: break-word; /* Evita desbordamientos de palabras largas */
}

/* .section-content-button */
 .button-main-section {
  padding: 1rem 2.5rem;
  background-color: #3786E9;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(22, 180, 233, 0.4);
  width: 70%;

}

 .button-main-section:hover {
  background-color:  #DCE05C;
  transition: background-color 0.3s;
  color: #fff;
}



.sections {
  width: 100%;
  max-width: 800px; /* Ajusta este valor según tus necesidades */
}

.section {
  border: 1px solid #ffffff;
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.section-header {
  display: flex;
  align-items: center; /* Alineación vertical */
  justify-content: flex-start; /* Alineación inicial en el inicio */
  width: 100%;
  transition: all 0.2s ease; /* Transición general */
}

.section-header div {
  width: 100%; /* Asegúrate de que el div ocupe todo el ancho */
  text-align: start;
/* transform: translateX(0);  Posición inicial sin desplazamiento */
  transition: transform 0.5s ease, text-align 1s ease, color 0.2s ease; /* Transición en transform y color */
}

.section-header div h3 {
 /*  transform: translateX(0); Mueve el contenido hacia el centro horizontalmente */
  transition: transform 0.5s ease, text-align 1s ease, color 0.2s ease; 
}
.section-header.txtopen div h3 {
 /*  transform: translateX(33%); Mueve el contenido hacia el centro horizontalmente */
  color: #3786E9; 
}


/* 
  transicion que respawnea de la menos a mas

.section-header.txtopen div  {
  color: #007bff;
  width: 100%;
  transition: all 0.5s ease; 
  transform: scale(1); 
  opacity: 1;
}

.section-header.txtopen div {
  width: 100%;
  transform: scale(1.5); 
  opacity: 0.8; 
} */


.section h3 {
  margin: 0;
  font-size: 1.5rem; /* Ajusta el tamaño de la fuente según sea necesario */
}

.section .arrow {
  font-size: 1.5rem; /* Tamaño de la flecha */
  color: #DCE05C; /* Color de la flecha */
  transition: transform 1s ease;
  margin-right: 2rem; /* Espacio entre la flecha y el título */
}

.section.open .arrow {
  transform: rotate(90deg);
  color: #3786E9;
}

.section-content {
  margin-top: 1rem;
  background-color: #ffffff; /* Fondo para la sección abierta */
  border-bottom: 1px solid #ddd;
  border-top: none;
  width: 100%;
}

@media (min-width:1080px){
  .container-subtitulo h2 {
    font-size: 1.5rem; 
    font-weight: 400;
    margin-bottom: 3rem;
    width: 60%;
    max-width: 100%; /* Limita el ancho para que el texto quede en 3 renglones */
    text-align: justify; /* Justifica el texto */
    word-wrap: break-word; /* Evita desbordamientos de palabras largas */
  }

  .button-main-section {
    padding: 1rem 2.5rem;
    background-color: #3786E9;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(22, 180, 233, 0.4);
    width: 60%;
  
  }

}

@media ( max-width:1440px){
  .section-content-button button {
  padding: 1; /* Más ancho que alto */
  width: auto;
  
}
}

@media ( max-width:850px){
  .section-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 3.5rem;
    padding-left: 0;
    padding-right: 0;
  }
  
    .section-slide h2 {
      font-size:15px; /* Ajusta el tamaño de la fuente según sea necesario */
      font-weight: 600;
      margin-bottom: 4rem;
      width: 80%;
    }
    .section-header div {
      width: 100%; /* Asegúrate de que el div ocupe todo el ancho */
      text-align: start;
     /* transform: translateX(0);  Posición inicial sin desplazamiento */
      transition: transform 1s ease, text-align 1s ease, color 1s ease; /* Transición en transform y color */
    }

   
    .section-header div h3 {
     /* transform: translateX(0);  Mueve el contenido hacia el centro horizontalmente */
      transition: transform 1s ease, text-align 1s ease, color 1s ease; 
    }
    .section-header.txtopen div h3 {
      /* transform: translateX(22%); */
      color: #3786E9; /* Mueve el contenido hacia el centro horizontalmente */
    
    }

    .sections {
      width: 100%;
      max-width: 100%; /* Ajusta este valor según tus necesidades */
      padding: 0;
    }
    
    .section h3 {
      margin: 0;
      font-size: 20px; /* Ajusta el tamaño de la fuente según sea necesario */
    }
    
    .section .arrow {
      font-size:20px; /* Tamaño de la flecha */
      color: #DCE05C; /* Color de la flecha */
      transition: transform 0.3s ease;
      margin-right: 2rem; /* Espacio entre la flecha y el título */
    }
    .section.open .arrow {
      transform: rotate(90deg);
      color: #3786E9;
    }
    
}

@media (max-width: 480px) {

  .section-content-button {
    margin: 2rem; /* Reduce el margen en móviles */
  }

  .section-content-button button {
    padding: 0.75rem 0.5rem; /* Ajusta el tamaño del botón */
    font-size: 0.875rem; /* Tamaño de fuente más pequeño */
    border-radius: 15px; /* Reduce el radio de los bordes */
  }

  .section-content-button button:hover {
    transform: scale(1.02); /* Ajuste menor al hover en móviles */
  }

  .section-content-button button:active {
    transform: scale(0.97); /* Ajuste menor al clic en móviles */
  }
}
