/* Navbar.css */

.navbar {
    display: flex;
    align-items: center;    
    justify-content: space-between;
    background-color: #ffffff;
    padding: 0.5rem;
    flex-wrap: wrap;
  }
  
  .logo img {
    height: 5rem;
    padding-right: 1.2rem;
  }
  
  .menu-toggle {
    display: none;
  }
  .menu-toggle.active {
    z-index: 9999; /* Valor alto para asegurar que esté por encima de todo */
  }
  /* Navegación */
  .nav-links {
    display: flex;
    /* padding-top: 0.5rem; */
    flex: 1;
    gap: 2rem;
    justify-content: center; /* Centra los enlaces */
  }
  
  .nav-links a {
    color: #000000;
    text-decoration: none;
    font-size: 1.125rem;
    transition: color 0.3s;
    align-content: end;
  }
  
  .nav-links a:hover {
    color: #DCE05C;
  }
  
  .signup-button {
    background-color: #3786E9;
    color: #fff;
    padding: 0.8rem 1.5rem;
    border-radius: 20px; /* Bordes redondeados para un look moderno */
    border: none; 
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s;
    margin-left: auto; /* Alinea el botón al final del contenedor */
  }
  
  .signup-button:hover {
    /* Cambia a un celeste más oscuro en hover */
    background-color:  #DCE05C;
    transition: background-color 0.3s;
    color: #fff;
  }
  
  /* Responsivo para pantallas pequeñas */
  @media (max-width:1440px){
    .logo img {
      height: 5rem;
      padding-right: 1.2rem;
    }
    .nav-links a {
      padding-top: 0.5rem;
    }
  }

  
  @media (max-width:800px){
    .navbar{
      display: flex;
    }

    .signup-button {
       display: none;
    }
    .nav-links{
      display: none;
    }
    .menu-toggle{
      width: 15%;
      display:flex;
      background: none;
      border: none;
      color: #000000;
      font-size: 1.5rem;
      cursor: pointer;
     
    }
    .menu-toggle:hover{
      width: 15%;
      display:flex;
      background: none;
      border: none;
      color: #000000;
      font-size: 1.5rem;
      cursor: pointer;
     
    }
  }