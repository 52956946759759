.content-legales {
    display: flex;
    flex-direction: column;
  }
  
  .content-legales-titles {
    color: #3786E9;
    display: flex;
    justify-content: center;
  }
  .section-buton-legal{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 .buton-legal{
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    align-items: center;
 }
 .btn-legal {
  background-color: #3786E9;
  color: #fff;
  display: flex;
  align-items: center; /* Alinea el ícono verticalmente con el texto */
  justify-content: center;
  padding: 0.8rem 1.2rem; /* Reduce el relleno lateral */
  border-radius: 20px; 
  border: none; 
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  gap: 0.5rem; /* Añade un espacio entre el texto y el ícono */
  transition: background-color 0.3s;
}

.btn-legal img {
  width: 1rem; /* Ajusta el ancho del ícono al tamaño del texto */
  height: auto; /* Mantén las proporciones de la imagen */
}

.btn-legal:hover {
  background-color: #DCE05C;
  color: #fff;
}

  .content-banners{
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .content-banners-grafics {

    display: flex;
    flex-direction:wrap;
    position: relative; /* Asegura que los hijos puedan posicionarse dentro del contenedor */
  }
  
  .banners-grafics {
    width:60%;
    position: relative; /* Posición relativa para poder usar 'absolute' en los hijos */
  }
  
  .banners-grafics img {
    width: 100%;
    height: auto;
    display: block;
  }

  .banners-grafics-text {
    border-bottom: 1px solid rgba(173, 172, 172, 0.782);
    border-top: 1px solid rgba(173, 172, 172, 0.782);
    width: 50%;
    padding: 10px;
    /* Flexbox para centrar el contenido */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra verticalmente */
    align-items: center; /* Centra horizontalmente */
    text-align: left; /* Alinea el texto a la izquierda */
  }


  .banners-grafics-text div {
    display: flex; /* Flexbox para centrar verticalmente */
    flex-direction: column; /* Asegura que el span y p estén en una columna */
    margin: 0; /* Elimina márgenes */
    align-items: flex-start; /* Alinea el contenido a la izquierda */
    justify-content: center; /* Centra verticalmente el contenido */
    text-align: left; /* Justifica el texto a la izquierda */
    padding: 10px; /* Añade algo de espacio interior */
    width: 70%; /* Asegura que ocupe todo el ancho disponible */
    font-size: 25px;
  }

  .banners-grafics-text .span1,
  .banners-grafics-text .span2 {
    color: #3786E9;
    font-weight: 600;
    display: block;
    margin-bottom: 5px; /* Ajusta el margen inferior para el espaciado entre el span y el párrafo */
    width: 100%; /* Asegura que ambos elementos ocupen el ancho completo */
    text-align: left; /* Alinea el texto a la izquierda */
  }

  .banners-grafics-text p {
    margin: 0; /* Elimina márgenes del párrafo */
    width: 100%; /* Asegura que ambos elementos ocupen el ancho completo */
    text-align: left; /* Alinea el texto a la izquierda */
  }
  .content-banners a {
    text-decoration: none;
    color: black;
  }

  .banners-grafics div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 1.5px solid grey ;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2; /* Contenido encima de la imagen */
    color: rgb(0, 0, 0); /* Ajusta el color del texto si es necesario */
    font-size: 24px;
    text-align: center;
   /* background: rgba(74, 74, 74, 0.294);  Opcional: agrega un fondo semitransparente para mejorar la legibilidad */
  }
  
  @media (max-width: 768px) {
  
  
    /* Asegura que las imágenes ocupen todo el ancho en mobile */
    .banners-grafics {
      width: 100%;
    }
  
    .banners-grafics img {
      width: 100%;
    }
    
    .banners-grafics-text .span1,
    .banners-grafics-text .span2 {
      font-size: 13px;
    }
    .banners-grafics-text p {
      font-size: 11px;
    }

  }